import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './VentaList.css'; // Asegúrate de que este archivo CSS esté importado

const getLocalDateString = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const VentaList = () => {
  const [ventas, setVentas] = useState([]);
  const [startDate, setStartDate] = useState(getLocalDateString(new Date())); // Fecha actual
  const [endDate, setEndDate] = useState(getLocalDateString(new Date())); // Fecha actual
  const navigate = useNavigate();

  const fetchVentas = useCallback(() => {
    fetch(`https://app.construgran.mx/nodeapi/ventas?start=${startDate}&end=${endDate}`)
      .then(response => response.json())
      .then(data => setVentas(data))
      .catch(error => console.error('Error fetching ventas:', error));
  }, [startDate, endDate]);

  useEffect(() => {
    fetchVentas(); // Llamada inicial para obtener ventas del día de hoy
  }, [fetchVentas]);

  const handleVentaClick = (ventaId) => {
    navigate(`/venta/${ventaId}`);
  };

  return (
    <div>
      <h2>Lista de Ventas</h2>
      <div className="filters">
        <label>Desde: </label>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <label>Hasta: </label>
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        <button onClick={fetchVentas}>Buscar</button>
      </div>
      {ventas.length > 0 ? (
        <table>
        <thead>
          <tr>
            <th>Número de Venta</th>
            <th>Usuario</th>
            <th>Importe</th>
            <th>Acción</th>
          </tr>
        </thead>
        <tbody>
          {ventas.length > 0 ? (
            ventas.map(venta => (
              <tr key={venta.VENTA}>
                <td data-label="Número de Venta">{venta.VENTA}</td>
                <td data-label="Usuario">{venta.USUARIO}</td>
                <td data-label="Importe">${venta.IMPORTE}</td>
                <td data-label="Acción">
                  <button className="ticket-button" onClick={() => handleVentaClick(venta.VENTA)}>
                    🎫
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">No se encontraron ventas en el rango de fechas seleccionado.</td>
            </tr>
          )}
        </tbody>
      </table>
      
      ) : (
        <p>No se encontraron ventas en el rango de fechas seleccionado.</p>
      )}
    </div>
  );
};

export default VentaList;
