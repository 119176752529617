// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import VentaList from './VentaList';
import VentaDetail from './VentaDetail';
import Menu from './Menu'; // Importa el componente del menú
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <h1>Nails Cosmetics</h1>
          <Menu /> {/* Agrega el componente del menú */}
          <Routes>
            <Route path="/" element={<VentaList />} />
            <Route path="/venta/:ventaId" element={<VentaDetail />} />
            {/* Agrega rutas para otras páginas según sea necesario */}
            <Route path="/inventario" element={<div>Inventario</div>} />
            <Route path="/entradas" element={<div>Entradas al Inventario</div>} />
            <Route path="/salidas" element={<div>Salidas del Inventario</div>} />
            <Route path="/usuarios" element={<div>Usuarios</div>} />
            <Route path="/configuracion" element={<div>Configuración</div>} />
          </Routes>
        </header>
      </div>
    </Router>
  );
}

export default App;
