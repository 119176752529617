// src/Menu.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Menu.css'; // Opcional, para estilos personalizados

const Menu = () => {
  return (
    <nav className="menu">
      <ul>
        <li><Link to="/">Ventas</Link></li>
        <li><Link to="/inventario">Inventario</Link></li>
        <li><Link to="/usuarios">Usuarios</Link></li>
        <li><Link to="/entradas">Entradas al Inv.</Link></li>
        <li><Link to="/salidas">Salidas del Inv.</Link></li>
        <li><Link to="/configuracion">Configuración</Link></li>
      </ul>
    </nav>
  );
};

export default Menu;
