import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './VentaDetail.css'; // Asegúrate de importar tu CSS

const VentaDetail = () => {
  const { ventaId } = useParams();
  const [venta, setVenta] = useState(null);

  useEffect(() => {
    if (ventaId) {
      fetch(`https://app.construgran.mx/nodeapi/ventas/${ventaId}`)
        .then(response => response.json())
        .then(data => setVenta(data))
        .catch(error => console.error('Error fetching venta details:', error));
    }
  }, [ventaId]);

  if (!venta) return <div>Cargando...</div>;

  return (
    <div className="ticket-container">
      <div className="ticket-header">
        <h2>Ticket de Venta</h2>
      </div>
      <table className="ticket-table">
        <tbody>
          <tr>
            <th>Venta No:</th>
            <td>{venta.NO_REFEREN}</td>
          </tr>
          <tr>
            <th>Cliente:</th>
            <td>{venta.CLIENTE}</td>
          </tr>
          <tr>
            <th>Fecha de Emisión:</th>
            <td>{new Date(venta.F_EMISION).toLocaleDateString()}</td>
          </tr>
        </tbody>
      </table>
      <h3>Productos:</h3>
      <table className="ticket-table">
        <thead>
          <tr>
            <th>Artículo</th>
            <th>Cantidad</th>
            <th>Precio</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(venta.PRODUCTOS) && venta.PRODUCTOS.length > 0 ? (
            venta.PRODUCTOS.map(producto => (
              <tr key={producto.ARTICULO}>
                <td>{producto.OBSERV}</td>
                <td>{producto.CANTIDAD}</td>
                <td>${producto.PRECIO}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No hay productos para esta venta.</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="ticket-total">
        Importe Total: ${venta.IMPORTE}
      </div>
      <div className="ticket-footer">
        ¡Gracias por su compra!
      </div>
    </div>
  );
};

export default VentaDetail;
